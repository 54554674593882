import { isNumber } from 'lodash'

// import Image2_1_1 from "@/static/image2/學生秋冬V領外套1.jpg";
// import Image2_1_2 from "@/static/image2/學生秋冬V領外套2.jpg";
// import Image2_1_3 from "@/static/image2/學生秋冬V領外套3.png";
// import Image2_2_1 from "@/static/image2/學生西裝外套1.jpg";
// import Image2_2_2 from "@/static/image2/學生西裝外套2.jpg";
// import Image3_3_1 from "@/static/image2/白色V領背心外套.png";
import Image4_1_1 from '@/static/image2/西裝最終版-男.jpg'
import Image5_1_1 from '@/static/image2/西裝最終版-女.jpg'
import jacketSize from '@/static/image2/校褸size.png'
import WhiteVest from '@/static/image2/白色V領背心外套.jpg'
import WhiteVestSizeChart from '@/static/image2/V領背心外套size.png'
import WhiteSweater from '@/static/image2/白色V領長袖外套.jpg'
import WhiteSweaterSizeChart from '@/static/image2/V領長袖外套size.png'
import SummerShirt from '@/static/image2/短袖白恤衫.jpg'
import SummerShirtSizeChart from '@/static/image2/男短袖恤衫size.png'
import SummerDress from '@/static/image2/短袖校裙.jpg'
import SummerDressSizeChart from '@/static/image2/女校裙size.png'
import schoolEmblem from '@/static/image2/校徽.jpg'
import winterShirt from '@/static/image2/男長袖恤衫.jpg'
import winterShirtSizeChart from '@/static/image2/男長袖恤衫size.png'
import descriptionTag from '@/static/image2/descriptionTag.png'

import { CLOTHES_SIZE_SEX } from '../../constant'

// function importAll(r) {
//   return r.keys().map(r);
// }

// console.log(importAll(require.context('@/static/image2/', false, /\.(png|jpe?g|svg)$/)));

// 第二版需求
export const uniformSecondData = [
  /*{
    Checked:false,
    key: "1",
    maxBuyCount: 10,
    uniformId: "#0001",
    name: "白色V領長袖外套",
    picture: Image2_1_3,
    imgData: [
      // { title: "學生秋冬V領外套1", img: Image2_1_1 },
      // { title: "學生秋冬V領外套2", img: Image2_1_2 },
      { title: "學生秋冬V領外套", img: Image2_1_3 },
    ],
    sizeOption: [
      { sizeName: "S1", price: 135.00 },
      { sizeName: "S2", price: 135.00 },
      { sizeName: "S3", price: 140.00 },
      { sizeName: "S4", price: 140.00 },
      { sizeName: "S5", price: 145.00 },
      { sizeName: "M1", price: 145.00 },
      { sizeName: "M2", price: 150.00 },
      { sizeName: "M3", price: 150.00 },
      { sizeName: "M4", price: 155.00 },
      { sizeName: "M5", price: 160.00 },
    ],
    description:
      "本產品面料天然棉花含量高，採用最新技紡織技術，保留了棉料的柔軟舒適，透氣吸濕性能，兼具不易變形等特點。讓小朋友穿得舒服，家長更放心。",
  },
  {
    Checked:false,
    key: "2",
    maxBuyCount: 10,
    uniformId: "#0002",
    name: "白色V領背心外套",
    picture: Image3_3_1,
    imgData: [{ title: "白色V領背心外套", img: Image3_3_1 }],
    sizeOption: [
      { sizeName: "S1", price: 113.00  },
      { sizeName: "S2", price: 113.00  },
      { sizeName: "S3", price: 113.00  },
      { sizeName: "S4", price: 118.00  },
      { sizeName: "S5", price: 118.00  },
      { sizeName: "M1", price: 118.00  },
      { sizeName: "M2", price: 123.00  },
      { sizeName: "M3", price: 123.00  },
      { sizeName: "M4", price: 128.00  },
      { sizeName: "M5", price: 128.00  },
    ],
    description:
      "本產品面料天然棉花含量高，採用最新技紡織技術，保留了棉料的柔軟舒適，透氣吸濕性能，兼具不易變形等特點。讓小朋友穿得舒服，家長更放心。",
  },*/
  // {
  //   Checked:false,
  //   key: "3",
  //   maxBuyCount: 10,
  //   size: CLOTHES_SIZE_SEX[0],
  //   uniformId: "#0003",
  //   name: "特別版度身校褸",
  //   picture: Image2_2_1,
  //   imgData: [
  //     { title: "學生西裝外套1", img: Image2_2_1 },
  //     { title: "學生西裝外套2", img: Image2_2_2 },
  //   ],
  //   sizeOption: [
  //     { sizeName: "男", price: 380.00  },
  //     { sizeName: "女", price: 380.00  },
  //   ],
  //   description: "",
  // },
  {
    Checked: false,
    key: '3',
    maxBuyCount: 10,
    uniformId: '#0003',
    name: '男裝西裝褸',
    picture: Image4_1_1,
    imgData: [{ title: '男裝西裝褸', img: Image4_1_1 }],
    sizeChart: jacketSize,
    sizeOption: [
      { sizeName: 'S', price: 380.0 },
      { sizeName: 'M', price: 380.0 },
      { sizeName: 'L', price: 380.0 },
      { sizeName: 'XL', price: 380.0 },
      { sizeName: '2XL', price: 380.0 },
      { sizeName: '3XL', price: 380.0 },
      { sizeName: '4XL', price: 380.0 },
      { sizeName: '度身定做', price: 380.0 },
    ],
    description: '現貨到貨時間：7天\n訂貨到貨時間：20工作天',
    productIntro: null,
  },
  {
    Checked: false,
    key: '4',
    maxBuyCount: 10,
    uniformId: '#0004',
    name: '女裝西裝褸',
    sizeChart: jacketSize,
    picture: Image5_1_1,
    imgData: [{ title: '女裝西裝褸', img: Image5_1_1 }],
    sizeOption: [
      { sizeName: 'S', price: 380.0 },
      { sizeName: 'M', price: 380.0 },
      { sizeName: 'L', price: 380.0 },
      { sizeName: 'XL', price: 380.0 },
      { sizeName: '2XL', price: 380.0 },
      { sizeName: '3XL', price: 380.0 },
      { sizeName: '4XL', price: 380.0 },
      { sizeName: '度身定做', price: 380.0 },
    ],
    description: '現貨到貨時間：7天\n訂貨到貨時間：20工作天',
    productIntro: null,
  },
  {
    Checked: false,
    key: '5',
    maxBuyCount: 10,
    uniformId: '#0005',
    name: '白色V領長袖外套',
    picture: WhiteSweater,
    sizeChart: WhiteSweaterSizeChart,
    imgData: [{ title: '白色V領長袖外套', img: WhiteSweater }],
    sizeOption: [
      { sizeName: 'K1', price: 135.0 },
      { sizeName: 'K2', price: 135.0 },
      { sizeName: 'S0.5', price: 135.0 },
      { sizeName: 'S1', price: 135.0 },
      { sizeName: 'S2', price: 135.0 },
      { sizeName: 'S3', price: 140.0 },
      { sizeName: 'S4', price: 140.0 },
      { sizeName: 'S5', price: 145.0 },
      { sizeName: 'SS', price: 145.0 },
      { sizeName: 'M1', price: 150.0 },
      { sizeName: 'M2', price: 150.0 },
      { sizeName: 'M3', price: 155.0 },
      { sizeName: 'M4', price: 150.0 },
      { sizeName: 'M5', price: 168.0 },
      { sizeName: 'M6', price: 168.0 },
      { sizeName: 'L', price: 178.0 },
    ],
    description: '現貨到貨時間：7天\n訂貨到貨時間：20工作天',
    productIntro:
      '本產品面料天然棉花含量高，採用最新紡織技術，保留了棉料的柔軟舒適，透氣吸濕性能，兼具不易變形等特點。讓小朋友穿得舒服，家長更放心。',
  },
  {
    Checked: false,
    key: '6',
    maxBuyCount: 10,
    uniformId: '#0006',
    name: '白色V領背心外套',
    picture: WhiteVest,
    sizeChart: WhiteVestSizeChart,
    imgData: [{ title: '白色V領背心外套', img: WhiteVest }],
    sizeOption: [
      { sizeName: 'K1', price: 113.0 },
      { sizeName: 'K2', price: 113.0 },
      { sizeName: 'S0.5', price: 113.0 },
      { sizeName: 'S1', price: 113.0 },
      { sizeName: 'S2', price: 113.0 },
      { sizeName: 'S3', price: 118.0 },
      { sizeName: 'S4', price: 118.0 },
      { sizeName: 'S5', price: 118.0 },
      { sizeName: 'SS', price: 118.0 },
      { sizeName: 'M1', price: 123.0 },
      { sizeName: 'M2', price: 123.0 },
      { sizeName: 'M3', price: 128.0 },
      { sizeName: 'M4', price: 128.0 },
      { sizeName: 'M5', price: 134.0 },
      { sizeName: 'M6', price: 134.0 },
      { sizeName: 'L', price: 142.0 },
    ],
    description: '現貨到貨時間：7天\n訂貨到貨時間：20工作天',
    productIntro:
      '本產品面料天然棉花含量高，採用最新紡織技術，保留了棉料的柔軟舒適，透氣吸濕性能，兼具不易變形等特點。讓小朋友穿得舒服，家長更放心。',
  },
  {
    Checked: false,
    key: '7',
    maxBuyCount: 10,
    uniformId: '#0007',
    name: '短袖白恤衫',
    picture: SummerShirt,
    sizeChart: SummerShirtSizeChart,
    descriptionTag: descriptionTag,
    imgData: [{ title: '短袖白恤衫', img: SummerShirt }],
    sizeOption: [
      { sizeName: 'S0.5', price: 88.0 },
      { sizeName: 'S1', price: 88.0 },
      { sizeName: 'S2', price: 88.0 },
      { sizeName: 'S3', price: 88.0 },
      { sizeName: 'S4', price: 88.0 },
      { sizeName: 'S5', price: 88.0 },
      { sizeName: 'M1', price: 98.0 },
      { sizeName: 'M2', price: 98.0 },
      { sizeName: 'M3', price: 98.0 },
      { sizeName: 'M4', price: 98.0 },
      { sizeName: 'M5', price: 98.0 },
      { sizeName: 'M6', price: 98.0 },
      { sizeName: 'M7', price: 98.0 },
      { sizeName: 'L', price: 98.0 },
    ],
    description: '現貨到貨時間：7天\n訂貨到貨時間：20工作天',
    productIntro:
      '本品採用天然木纖維加工而成莫代爾布料，環保易降解。透氣性強，兼具優秀的抗皺性能，護理更省心。布料經過銀離子抗菌處理，具有抗菌、防臭汗功能。',
  },
  {
    Checked: false,
    key: '8',
    maxBuyCount: 10,
    uniformId: '#0008',
    name: '短袖校裙',
    picture: SummerDress,
    sizeChart: SummerDressSizeChart,
    descriptionTag: descriptionTag,
    imgData: [{ title: '短袖校裙', img: SummerDress }],
    sizeOption: [
      { sizeName: 'S1', price: 115.0 },
      { sizeName: 'S2', price: 115.0 },
      { sizeName: 'S3', price: 115.0 },
      { sizeName: 'S4', price: 115.0 },
      { sizeName: 'S5', price: 127.0 },
      { sizeName: 'M1', price: 127.0 },
      { sizeName: 'M2', price: 127.0 },
      { sizeName: 'M3', price: 135.0 },
      { sizeName: 'M4', price: 135.0 },
      { sizeName: 'M5', price: 135.0 },
    ],
    description: '現貨到貨時間：7天\n訂貨到貨時間：20工作天',
    productIntro:
      '本品採用天然木纖維加工而成莫代爾布料，環保易降解。透氣性強，兼具優秀的抗皺性能，護理更省心。布料經過銀離子抗菌處理，具有抗菌、防臭汗功能。',
  },
  {
    Checked: false,
    key: '9',
    maxBuyCount: 10,
    uniformId: '#0009',
    name: '校徽',
    picture: schoolEmblem,
    imgData: [{ title: '校徽', img: schoolEmblem }],
    sizeOption: [{ sizeName: 'F', price: 10.0 }],
    description: '現貨到貨時間：7天\n訂貨到貨時間：20工作天',
  },
  {
    Checked: false,
    key: '10',
    maxBuyCount: 10,
    uniformId: '#0010',
    name: '長袖白恤衫',
    picture: winterShirt,
    sizeChart: winterShirtSizeChart,
    descriptionTag: descriptionTag,
    imgData: [{ title: '長袖白恤衫', img: winterShirt }],
    sizeOption: [
      { sizeName: 'S0.5', price: 108.0 },
      { sizeName: 'S1', price: 108.0 },
      { sizeName: 'S2', price: 108.0 },
      { sizeName: 'S3', price: 108.0 },
      { sizeName: 'S4', price: 108.0 },
      { sizeName: 'S5', price: 108.0 },
      { sizeName: 'M1', price: 118.0 },
      { sizeName: 'M2', price: 118.0 },
      { sizeName: 'M3', price: 118.0 },
      { sizeName: 'M4', price: 118.0 },
      { sizeName: 'M5', price: 118.0 },
      { sizeName: 'M6', price: 118.0 },
      { sizeName: 'M7', price: 118.0 },
      { sizeName: 'L', price: 118.0 },
    ],
    description: '現貨到貨時間：7天\n訂貨到貨時間：20工作天',
    productIntro:
      '本品採用天然木纖維加工而成莫代爾布料，環保易降解。透氣性強，兼具優秀的抗皺性能，護理更省心。布料經過銀離子抗菌處理，具有抗菌、防臭汗功能。',
  },
]

export const getPrice = (uniformList = []) => {
  let price = 0
  uniformList.map((list) => {
    if (isNumber(list.price) && isNumber(list.quantity)) {
      price += list.price * list.quantity
    }
    return list
  })
  return price
}

export const getFormListByUniform = (list = []) => {
  return list
    ?.filter((item) => item.Checked)
    ?.map((item) => ({
      quantity: item?.quantity,
      size: item?.size,
      schoolUniformName: item?.name,
      price: item?.price,
    }))
}

export const getAllPrice = (uniformList) => {
  return getPrice(getFormListByUniform(uniformList))
}

export const priceFormat = (num = 0) => {
  return `${num}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
}

const getLabelByOptions = (keyName = '', row = {}, options = []) => {
  return options.filter((item) => item.value === row[keyName])[0]?.label
}

export const getUniformParams = (values = {}, SchoolOptions = []) => {
  const uniformList = getFormListByUniform(values?.list)
  return {
    // schoolId: values.schoolId,
    schoolName: getLabelByOptions('schoolId', values, SchoolOptions),
    schoolClass: values.schoolClass,
    studentFirstName: values.studentFirstName,
    studentLastName: values.studentLastName,
    studentNumber: values.studentNumber,
    studentEmail: values.studentEmail,
    studentPhone: values.studentPhone,
    remark: values.remark,
    totalPrice: getPrice(uniformList),
    schoolUniformList: uniformList,
  }
}

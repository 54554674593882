import React, { useState, useCallback, useEffect } from "react";
import { Form, DatePicker, Input, Button, Table, Row, Col, Space, message, Radio } from "antd";

import * as API from "@/util/service/service";
import * as URL from "@/util/service/url";
import { LAYOUT_COL_FOUR_ONE, downloadFile } from '@/util/common';

import CustomTable from '@/component/CustomTable';
import dayjs from "dayjs";
import { ExportOutlined, SearchOutlined } from "@ant-design/icons";
import RadioCheckboxGroup from "@/component/RadioCheckboxGroup";

import './index.css'


function Order() {
  const [form] = Form.useForm()

  const [pageSize, setPageSize] = useState(10)
  const [pageNo, setPageNo] = useState(1)
  const [dataSource, setDataSource] = useState([])
  const [total, setTotal] = useState(0)
  const [loading, setLoading] = useState(false)
  const [value, setValue] = useState(null)
  // const [selectedRecord, setSelectedRecord] = useState(null);

  const onRadioClick = (record) => {
    const params = {
      id: record.id,
      color: record.color
    }
    console.log(record.color);
    setLoading(true)
    API.postBodies(URL.ORDER_UPDATE, params).then(({ status }) => {
      if (status === 200) {
        getList()
      }
    }).finally(() => setLoading(false))
  }


  const columns = [
    {
      title: '訂單ID',
      dataIndex: 'orderGroupId',
    },
    {
      title: '下單日期',
      dataIndex: 'createdAt',
    },
    {
      title: '學校',
      dataIndex: 'schoolName',
    },
    {
      title: '學生姓名',
      dataIndex: 'studentUserName',
    },
    {
      title: '班別',
      dataIndex: 'schoolClass',
    },
    {
      title: '學號',
      dataIndex: 'studentNumber',
    },
    {
      title: '連絡電話',
      dataIndex: 'studentPhone',
    },
    {
      title: '電郵',
      dataIndex: 'studentEmail',
    },
    {
      title: '總價',
      dataIndex: 'totalPrice',
    },
    {
      title: '狀態',
      dataIndex: 'status',
    },
    {
      title: '備註',
      dataIndex: 'remark',
    },
    {
      title: '染色',
      dataIndex: 'color',
      align: 'center',
      render: (value, record) => (
        <RadioCheckboxGroup value={value} onChange={(e) => onRadioClick({ ...record, color: e })}
          options={[
            { value: 'blue', className: 'bg_blue_checkbox' },
            { value: 'yellow', className: 'bg_yellow_checkbox' }
          ]}
        >
        </RadioCheckboxGroup>
      )
    }

    // {
    //   title: '校服',
    //   dataIndex: '',
    // },
    // {
    //   title: '尺寸',
    //   dataIndex: 'size',
    // },
    // {
    //   title: '數量',
    //   dataIndex: 'quantity',
    // },
    // {
    //   title: '單價',
    //   dataIndex: 'price',
    // }
  ]

  const getList = useCallback(() => {
    const { DateTime, ...field } = form.getFieldsValue()
    setLoading(true)
    const params = {
      pageSize,
      pageNo,
      ...field,
    }
    DateTime?.[0] && (params.startTime = dayjs(DateTime[0]).startOf('day').format('YYYY-MM-DD HH:mm:ss'))
    DateTime?.[0] && (params.endTime = dayjs(DateTime[1]).endOf('day').format('YYYY-MM-DD HH:mm:ss'))

    API.getBodies(URL.ORDER_LIST, params).then(({ status, data: res }) => {
      if (status === 200) {
        setDataSource(res.data)
        setTotal(res.total)
      }
    }).finally(() => setLoading(false))
  }, [pageSize, pageNo])

  useEffect(() => {
    getList({
      pageSize,
      pageNo,
    })
  }, [getList, pageSize, pageNo])



  const expandedRowRender = (record) => {
    const columns = [
      {
        title: '校服',
        dataIndex: 'schoolUniformName',
        key: 'schoolUniformName',
      },
      {
        title: '尺寸',
        dataIndex: 'size',
        key: 'size',
      },
      {
        title: '數量',
        dataIndex: 'quantity',
        key: 'quantity',
      },
      {
        title: '單價',
        dataIndex: 'price',
        key: 'price',
      }
    ]
    const dataSource = record.schoolUniformList.map((el, index) => ({
      key: record.orderGroupId + index,
      ...el
    }))

    return <Table bordered size="small" columns={columns} dataSource={dataSource} pagination={false} />;
  }

  const handleOnExport = useCallback(() => {
    return form
      .validateFields()
      .then(({ DateTime, ...vals }) => {
        const params = {
          ...vals,
        }
        DateTime?.[0] && (params.startTime = dayjs(DateTime[0]).startOf('day').format('YYYY-MM-DD HH:mm:ss'))
        DateTime?.[0] && (params.endTime = dayjs(DateTime[1]).endOf('day').format('YYYY-MM-DD HH:mm:ss'))

        return API.postBodies(URL.ORDER_EXPORT_EXCEL, params, {
          responseType: 'blob'
        }).then((res) => {
          if (res.status === 200) {
            message.success('導出成功')
            downloadFile(res)
          }
        })
      }).catch(err => console.log(err))
  }, [])

  return <>
    <Form
      form={form}
      onFinish={() => pageNo !== 1 ? setPageNo(1) : getList({ pageNo: 1, pageSize })}>
      <Row gutter={[8, 0]}>
        <Col {...LAYOUT_COL_FOUR_ONE}>
          <Form.Item
            label="班別"
            name="schoolClass"
          >
            <Input allowClear />
          </Form.Item>
        </Col>
        <Col {...LAYOUT_COL_FOUR_ONE}>
          <Form.Item
            label="學生姓名"
            name="studentUserName"
          >
            <Input allowClear />
          </Form.Item>
        </Col>
        <Col {...LAYOUT_COL_FOUR_ONE}>
          <Form.Item
            label="連絡電話"
            name="studentPhone"
          >
            <Input allowClear />
          </Form.Item>
        </Col>
        <Col {...LAYOUT_COL_FOUR_ONE}>
          <Form.Item
            label="學生郵箱"
            name="studentEmail"
          >
            <Input allowClear />
          </Form.Item>
        </Col>
        <Col {...LAYOUT_COL_FOUR_ONE}>
          <Form.Item
            label="訂單時間"
            name="DateTime"
          >
            <DatePicker.RangePicker allowClear className="w-full" />
          </Form.Item>
        </Col>
        <Col {...LAYOUT_COL_FOUR_ONE}>
          <Form.Item shouldUpdate>
            {() => (
              <Space>
                <Button
                  type="primary"
                  htmlType="submit"
                  loading={loading}
                  icon={<SearchOutlined />}
                >
                  查詢
                </Button>

                <Button
                  type="primary"
                  icon={<ExportOutlined />}
                  onClick={handleOnExport}
                >
                  導出
                </Button>
              </Space>
            )}
          </Form.Item>
        </Col>
      </Row>
    </Form>
    <CustomTable
      loading={loading}
      columns={columns}
      dataSource={dataSource}
      rowClassName={(record) => {
        return `bg_${record?.color}`
      }}
      pagination={{
        pageSize,
        current: pageNo,
        total,
        onShowSizeChange: setPageSize,
        onChange: setPageNo
      }}
      expandable={{
        expandedRowRender
      }}
    />
  </>
}
export default Order